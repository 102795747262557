<template>
    <div>
        <TimeTracking :employee="employee" v-if="notEmptyObject(employee)" :next="next" :previous="previous"/>
    </div>
</template>

<script>
import TimeTracking from './components/TimeTracking.vue'
export default {
  components: { TimeTracking },
    name: "TimeTrackingPage",
    title: "Zeiterfassung",
    data() {
        return {
            employee: {},
            next: {},
            previous: {}
        }
    },
    methods: {
        getEmployee(){
            this.axios
                .get("/employees/" + this.$route.params.id)
                .then((response) => {
                    this.employee = response.data.data;
                    this.next = response.data.next;
                    this.previous = response.data.previous;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },
    mounted() {
        this.getEmployee();
    }
}
</script>

<style>

</style>